import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While playing around with Chaikin curves (which I first heard about from `}<a parentName="p" {...{
        "href": "https://www.benkovach.art/"
      }}>{`Ben Kovach`}</a>{` in his `}<a parentName="p" {...{
        "href": "https://youtu.be/kZNTozzsNqk"
      }}>{`talk about generative art`}</a>{`) I saw that using them to connect some random points on a screen made some cool-looking glyphs. A randomly generated language!`}</p>
    <h2>{`Overview`}</h2>
    <p>{`Chaikin curves (developed by George Chaikin in '74) create a smooth curve from a series of points by cutting the corners off of the curve.`}</p>
    <p>{`Given a set of points, we take each line segment and:`}</p>
    <ol>
      <li parentName="ol">{`Split it into three segments: the first 25% of the curve, the middle 50%, and the last 25%.`}</li>
      <li parentName="ol">{`Discard the first and last quarters of the original segment`}</li>
      <li parentName="ol">{`Save the start and end points of the middle line segment as the new points in the curve`}</li>
    </ol>
    <p>{`In doing so, we add more points each time we run the algorithm, with the line segments getting shorter, resulting in a smoother curve. We can run the algorithm recursively and get progressively shorter line segments and a smoother line.`}</p>
    <p>{`And then if we're dealing with a closed shape instead of a series of line segments, we also need to make a line segment out of the first and last points.`}</p>
    <p>{`That's pretty much it, so let's build it.`}</p>
    <h2>{`Coding the algorithm`}</h2>
    <p>{`First, we're going to code the algorithm, and then we'll use it on a canvas to make some glyphs that are pretty and unintelligible (what I strive for in my life, too).`}</p>
    <p>{`Let's start off with some tests so that we're clear on what we're building.`}</p>
    <h3>{`Base case`}</h3>
    <p>{`This is going to be a recursive algorithm. On the first run through with this algorithm, we're still going to have a pretty jagged curve. But once we run through it four or five times, the curve's going to be as smooth as butter.`}</p>
    <p>{`Since it's recursive, we want to return the points of the curve once we have no more steps so that we don't get caught in an infinite loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// chaikin.js
// javascript's standard assert library - no need to install anything for this
const { deepEqual } = require("assert")

// write a simple assert method with slightly better logging
const assert = (actual, expected, message) => {
  try {
    deepEqual(actual, expected, message)
  } catch (err) {
    console.error("ERROR! ", message)
    console.log("Expected: ")
    console.dir(err.expected)
    console.log("Actual: ")
    console.dir(err.actual)
  }
}

const chaikin = () => {
  /* Coming soon! */
  return {}
}

// our test points
const points = [
  [100, 100],
  [400, 300],
  [700, 200],
]
assert(
  chaikin({ points, step: 0 }).points,
  points,
  "returns input points once base case is reached"
)
`}</code></pre>
    <p>{`If we run this test (with `}<inlineCode parentName="p">{`node chaikin.js`}</inlineCode>{`), we get an error, since the `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` function doesn't currently do anything. So, let's get the test passing:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { deepEqual } = require("assert")
const assert = (actual, expected, message) => {
  try {
    deepEqual(actual, expected, message)
  } catch (err) {
    console.error("ERROR! ", message)
    console.log("Expected: ")
    console.dir(err.expected)
    console.log("Actual: ")
    console.dir(err.actual)
  }
}

// NEW!
const chaikin = ({ points, level }) => {
  if (step <= 0) {
    // return this in an object, in case we need to return more properties later
    return { points }
  }
}

const points = [
  [100, 100],
  [400, 300],
  [700, 200],
]
assert(
  chaikin({ points, step: 0 }).points,
  points,
  "returns input points once base case is reached"
)
`}</code></pre>
    <h3>{`Recursive case for open curves`}</h3>
    <p>{`Base case is covered! Now we can start finding the points. We'll start with open curves. The test case is going to involve us doing a little bit of math by hand with our test points to ensure we're looking for the correct numbers.`}</p>
    <h4>{`Calculating test case result by hand`}</h4>
    <p>{`Given points of `}<inlineCode parentName="p">{`[100,100], [400, 300], [700, 200]`}</inlineCode>{`, what would we expect the output to be? The algorithm says that we want one point to be one quarter through the line segment, and the other point to be three quarters through. Let's calculate the end points of the middle 50% line segment.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "boxShadow": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
          "marginTop": "24px",
          "marginBottom": "24px",
          "maxWidth": "2246px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/27fb2439432875226fbefc35305f5c5d/c1999/chaikin-line-segment.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.20837043633126%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd6AsD//xAAWEAADAAAAAAAAAAAAAAAAAAAAECH/2gAIAQEAAQUCKv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABcQAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQEAAT8hdJj/2gAMAwEAAgADAAAAEAgP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAITGBsf/aAAgBAQABPxATS6ZYMDZgauf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chaikin line segment",
            "title": "chaikin line segment",
            "src": "/static/27fb2439432875226fbefc35305f5c5d/c1999/chaikin-line-segment.jpg",
            "srcSet": ["/static/27fb2439432875226fbefc35305f5c5d/c1999/chaikin-line-segment.jpg 2246w"],
            "sizes": "(max-width: 2246px) 100vw, 2246px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We know the first point and second point (`}<inlineCode parentName="p">{`[100,100]`}</inlineCode>{` and `}<inlineCode parentName="p">{`[400, 300]`}</inlineCode>{`) `}<inlineCode parentName="p">{`x`}</inlineCode>{` values are `}<inlineCode parentName="p">{`400 - 100 = 300`}</inlineCode>{` pixels apart. 25% of that is `}<inlineCode parentName="p">{`75`}</inlineCode>{`, and 75% is `}<inlineCode parentName="p">{`225`}</inlineCode>{`, so we would expect `}<inlineCode parentName="p">{`x1`}</inlineCode>{` to be at `}<inlineCode parentName="p">{`100 + 75 = 175`}</inlineCode>{` and `}<inlineCode parentName="p">{`x2`}</inlineCode>{` to be at `}<inlineCode parentName="p">{`100 + 225 = 325`}</inlineCode>{`.`}</p>
    <p>{`For the `}<inlineCode parentName="p">{`y`}</inlineCode>{` values, we've got a difference of `}<inlineCode parentName="p">{`300 - 100 = 200`}</inlineCode>{`. 25% and 75% are `}<inlineCode parentName="p">{`50`}</inlineCode>{` and `}<inlineCode parentName="p">{`150`}</inlineCode>{`, respectively. So, `}<inlineCode parentName="p">{`y1`}</inlineCode>{` and `}<inlineCode parentName="p">{`y2`}</inlineCode>{` are going to be `}<inlineCode parentName="p">{`100 + 50`}</inlineCode>{` and `}<inlineCode parentName="p">{`100 + 150`}</inlineCode>{`.`}</p>
    <p>{`So, the two points that come from the first line segment are `}<inlineCode parentName="p">{`[175, 150]`}</inlineCode>{` and `}<inlineCode parentName="p">{`[325, 250]`}</inlineCode>{`.`}</p>
    <p>{`And for the second line segment (`}<inlineCode parentName="p">{`[400, 300]`}</inlineCode>{` and `}<inlineCode parentName="p">{`[700, 200]`}</inlineCode>{`), the two points will be: `}<inlineCode parentName="p">{`[475, 270]`}</inlineCode>{` and `}<inlineCode parentName="p">{`[625, 225]`}</inlineCode>{`.`}</p>
    <p>{`Let's add those for the test case, using a `}<inlineCode parentName="p">{`step`}</inlineCode>{` of `}<inlineCode parentName="p">{`1`}</inlineCode>{` so that we don't hit the base case immediately.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`deepEqual(
  chaikin({ points, step: 1 }).points,
  [
    [175, 150],
    [325, 250],
    [475, 275],
    [625, 225],
  ],
  "returns points 25% and 75% through each line segment"
)
`}</code></pre>
    <h4>{`Finding the formula to calculate these values`}</h4>
    <p>{`Okay, we've got our test case. But as it stands, it's going to be hard to write an algorithm to calculate these points with the step-by-step math. Let's find a formula to make this all easier.`}</p>
    <p>{`To get the first points values, the 25% one, we took the difference between the `}<inlineCode parentName="p">{`x`}</inlineCode>{` values and then added `}<inlineCode parentName="p">{`25%`}</inlineCode>{` of that difference to the first point's `}<inlineCode parentName="p">{`x`}</inlineCode>{` value.`}</p>
    <p>{`And for the second point, the `}<inlineCode parentName="p">{`75%`}</inlineCode>{` one, we took the difference between the `}<inlineCode parentName="p">{`x`}</inlineCode>{` values and then added `}<inlineCode parentName="p">{`75%`}</inlineCode>{` of that difference to the first `}<inlineCode parentName="p">{`x`}</inlineCode>{` value. In a formula:`}</p>
    <pre><code parentName="pre" {...{}}>{`point1's x:
xP1 = x1 + 0.25 * (x2 - x1)
xP1 = x1 + (0.25 * x2) - (0.25 * x1)
xP1 = (0.75 * x1) + (0.25 * x2)

point2's x:
xP2 = x1 + (0.75) * (x2 - x1)
xP2 = x1 + (0.75 * x2) - (0.75 * x1)
xP2 = (0.25 * x1) + (0.75 * x2)
`}</code></pre>
    <p>{`Okay! Let's get these formulas into the `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` algorithm. For each line segment, we need to use these formulas on both the `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` values to create the two new points.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const chaikin = ({ points, step }) => {
  if (step <= 0) {
    return { points }
  }

  // NEW!
  const result = []
  for (let i = 0; i < points.length - 1; i++) {
    // current and next point in the curve
    const pointA = points[i]
    const pointB = points[i + 1]

    const [xA, yA] = pointA
    const [xB, yB] = pointB

    // 25%-in point's x and y
    const x1 = 0.25 * xB + 0.75 * xA
    const y1 = 0.25 * yB + 0.75 * yA

    // 75%-in point's x and y
    const x2 = 0.75 * xB + 0.25 * xA
    const y2 = 0.75 * yB + 0.25 * yA

    result.push([x1, y1])
    result.push([x2, y2])
  }

  // call chaikin again with:
  // - one fewer step remaining
  // - our result points that we just calculated as the new input
  return chaikin({ points: result, step: step - 1 })
}
`}</code></pre>
    <p>{`When we run the tests again, all should be passing. At this point, we could run the `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` function with greater numbers of levels, and we'd get a smoother and smoother curve, as we'll see displayed soon. But first, let's cover the case for closed polygons.`}</p>
    <h3>{`Covering the closed curve case`}</h3>
    <p>{`The difference here between the open curves is that we need to make one last extra line segment that connects the last point with the first point.`}</p>
    <p>{`Using the same input points for our test case, we'd now expect to get 2 more points, from the `}<inlineCode parentName="p">{`[700, 500]`}</inlineCode>{` and `}<inlineCode parentName="p">{`[100,100]`}</inlineCode>{` line segment.`}</p>
    <p>{`Let's calculate these two new points' values by hand first again:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const x1 = 0.75 * 700 + 0.25 * 100 // 550
const y1 = 0.75 * 200 + 0.25 * 100 // 175

const x2 = 0.25 * 700 + 0.75 * 100 // 250
const y2 = 0.25 * 200 + 0.75 * 100 // 125
`}</code></pre>
    <p>{`The last two expected points are `}<inlineCode parentName="p">{`[550, 175]`}</inlineCode>{` and `}<inlineCode parentName="p">{`[250, 125]`}</inlineCode>{`. We'll also need to told when we run the function whether we should calculate these last two points with a boolean flag. I'll name it `}<inlineCode parentName="p">{`isClosed`}</inlineCode>{`. The test case will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`assert(
  chaikin({ points, step: 1, isClosed: true }).points, // new isClosed flag is true
  [
    [175, 150],
    [325, 250],
    [475, 350],
    [625, 450],
    [550, 175],
    [250, 125],
  ],
  "returns points 25% and 75% through each line segment for a closed polygon"
)
`}</code></pre>
    <p>{`Running this test fails because we're not doing anything with the `}<inlineCode parentName="p">{`isClosed`}</inlineCode>{` value in our `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` function yet. If the value is `}<inlineCode parentName="p">{`true`}</inlineCode>{`, we just want to run the `}<inlineCode parentName="p">{`for`}</inlineCode>{` loop one more time, meaning we'll run the function `}<inlineCode parentName="p">{`points.length`}</inlineCode>{` times (`}<inlineCode parentName="p">{`3`}</inlineCode>{`, in our test case) instead of `}<inlineCode parentName="p">{`points.length - 1`}</inlineCode>{` times. Let's toss that in:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// NEW! add the isClosed argument, defaulting to false
const chaikin = ({ points, step, isClosed = false }) => {
  if (step <= 0) {
    return { points, isClosed }
  }

  const result = []

  // NEW! use the isClosed variable to determine the max for loop index
  const maxIndex = isClosed ? points.length : points.length - 1
  for (let i = 0; i < maxIndex; i++) {
    const pointA = points[i]
    const pointB = points[i + 1]

    const [xA, yA] = pointA
    const [xB, yB] = pointB

    // 25% in point's x and y
    const x1 = 0.25 * xB + 0.75 * xA
    const y1 = 0.25 * yB + 0.75 * yA

    // 75% in point's x and y
    const x2 = 0.75 * xB + 0.25 * xA
    const y2 = 0.75 * yB + 0.25 * yA

    result.push([x1, y1])
    result.push([x2, y2])
  }

  // NEW! pass the isClosed value to any future calls
  return chaikin({ points: result, step: step - 1, isClosed })
}
`}</code></pre>
    <p>{`Almost there, but we've still got a problem. If you can't spot it, you can see an error message when we try to run this as is. If the curve is closed, `}<inlineCode parentName="p">{`i`}</inlineCode>{` can get up to `}<inlineCode parentName="p">{`2`}</inlineCode>{` with our test input points of length `}<inlineCode parentName="p">{`3`}</inlineCode>{`. When we get to `}<inlineCode parentName="p">{`i`}</inlineCode>{` being `}<inlineCode parentName="p">{`2`}</inlineCode>{`, we can calculate `}<inlineCode parentName="p">{`pointA`}</inlineCode>{` as `}<inlineCode parentName="p">{`points[2]`}</inlineCode>{`. But when we try to get `}<inlineCode parentName="p">{`pointB`}</inlineCode>{`, we're trying to get `}<inlineCode parentName="p">{`points[3]`}</inlineCode>{`, which doesn't exist!`}</p>
    <p>{`Instead, we want to get `}<inlineCode parentName="p">{`points[0]`}</inlineCode>{`, the first point, for `}<inlineCode parentName="p">{`pointB`}</inlineCode>{`. `}<inlineCode parentName="p">{`0`}</inlineCode>{` just so happens to be the remainder when we divide the index of `}<inlineCode parentName="p">{`3`}</inlineCode>{` by `}<inlineCode parentName="p">{`points.length`}</inlineCode>{`. We'll use that fact in order to prevent our current error with the `}<em parentName="p">{`modulus`}</em>{`, or remainder, operator. Then, instead of finding `}<inlineCode parentName="p">{`points[3]`}</inlineCode>{`, we'll look for `}<inlineCode parentName="p">{`points[(the remainder of i+1) / points.length] == points[0 / 3] == points[0]`}</inlineCode>{`.`}</p>
    <p>{`Let's make the switch:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const chaikin = ({ points, step, isClosed = false }) => {
  if (step <= 0) {
    return { points, isClosed }
  }

  const result = []
  const maxIndex = isClosed ? points.length : points.length - 1
  for (let i = 0; i < maxIndex; i++) {
    const pointA = points[i]
    // NEW: use the modulus operator to avoid an index that's too high
    const pointB = points[(i + 1) % points.length]

    const [xA, yA] = pointA
    const [xB, yB] = pointB

    // 25% in point's x and y
    const x1 = 0.25 * xB + 0.75 * xA
    const y1 = 0.25 * yB + 0.75 * yA

    // 75% in point's x and y
    const x2 = 0.75 * xB + 0.25 * xA
    const y2 = 0.75 * yB + 0.25 * yA

    result.push([x1, y1])
    result.push([x2, y2])
  }

  return chaikin({ points: result, step: step - 1, isClosed })
}
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` function is complete! We've got some test cases to give us some confidence that the algorithm is working. Let's get it onto a canvas now to see it in action.`}</p>
    <h2>{`Using the algorithm on a canvas`}</h2>
    <h3>{`Set up the canvas`}</h3>
    <p>{`To start using this on a canvas, we'll need to make an `}<inlineCode parentName="p">{`html`}</inlineCode>{` page. We can take our `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` implementation and put it into a `}<inlineCode parentName="p">{`script`}</inlineCode>{` tag at the end of the body.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- index.html -->
<body>
  <canvas></canvas>
  <script>
    const chaikin = ({ points, step, isClosed = false }) => {
      /* exact implementation we just wrote */
    }

    var canvas = document.querySelector("canvas")
    var context = canvas.getContext("2d")

    // set the size to smaller of window width or height
    var size = Math.min(window.innerWidth, window.innerHeight)
    canvas.width = size - 100
    canvas.height = size - 100

    // draw a gray rectangle covering the entire canvas
    context.rect(0, 0, canvas.width, canvas.height)
    context.fillStyle = "rgba(0,0,0, 0.1)"
    context.fill()
  </script>
</body>
`}</code></pre>
    <p>{`If you open up this `}<inlineCode parentName="p">{`html`}</inlineCode>{` file and see a gray box, all is working well. You can also throw all of this code into an HTML file on Codepen to see it all working:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/ZEbBKvP"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/ZEbBKvP/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Draw some curves`}</h3>
    <p>{`First, let's just draw some hard-coded points. Let's move down the canvas and ping pong between 0 and the full width as the x coordinates. Then, we'll draw both that curve and the chaikin curve with 5 steps of recursion, which should lead to a pretty smooth curve.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<body>
  <canvas></canvas>

  <script>
    const chaikin = ({ points, step, isClosed = false }) => {
      /* ... */
    }
    var canvas = document.querySelector("canvas")
    var context = canvas.getContext("2d")

    var size = Math.min(window.innerWidth, window.innerHeight)
    canvas.width = size - 100
    canvas.height = size - 100

    context.rect(0, 0, canvas.width, canvas.height)
    context.fillStyle = "rgba(0,0,0, 0.1)"
    context.fill()

    // NEW!
    // create the points
    const points = [
      [0, 0],
      [canvas.width, canvas.height * 0.25],
      [0, canvas.height * 0.5],
      [canvas.width, canvas.height * 0.75],
      [0, canvas.height],
    ]

    // draw the original points as a thin black line
    context.beginPath()
    context.lineWidth = 1
    context.moveTo(points[0][0], points[0][1])
    points.forEach(([x, y]) => {
      context.lineTo(x, y)
    })
    context.stroke()

    // draw the chaikin-ed points
    const { points: chaikinPoints } = chaikin({ points, step: 5 })
    context.lineWidth = 5
    context.strokeStyle = "red"
    context.beginPath()
    context.moveTo(chaikinPoints[0][0], chaikinPoints[0][1])
    chaikinPoints.forEach(([x, y]) => {
      context.lineTo(x, y)
    })
    context.stroke()
  </script>
</body>
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/wvKodEj"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/wvKodEj/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Draw a glyph`}</h3>
    <p>{`A chaikin curve on a canvas! We did it! If we want to make some random glyphs, all we'll need to do is use random points instead of the hard-coded ping-pong points I made.`}</p>
    <p>{`To make this easier on ourselves, let's make a helper function to create a point. Given a starting `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{`, as well as a `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` of the box that the point needs to be in, we'll return an `}<inlineCode parentName="p">{`[x,y]`}</inlineCode>{` point with a random coordinates inside of that box.`}</p>
    <p>{`Then we'll create a set of 10 or so random points and draw a curve of those ten points at 4 steps.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<body>
  <canvas></canvas>
  <script>
    const chaikin = ({ points, step, isClosed = false }) => {
      /* ... */
    }

    var canvas = document.querySelector("canvas")
    var context = canvas.getContext("2d")

    var size = Math.min(window.innerWidth, window.innerHeight)
    canvas.width = size - 100
    canvas.height = size - 100
    context.lineWidth = 1
    context.rect(0, 0, canvas.width, canvas.height)
    context.fillStyle = "rgba(0,0,0, 0.1)"
    context.fill()

    // NEW!
    const createRandomPoint = ({ x, y, width, height }) => {
      const randomX = x + Math.random() * width
      const randomY = y + Math.random() * height
      return [Math.floor(randomX), Math.floor(randomY)]
    }

    const points = []
    const pointsCount = 10
    for (let i = 0; i < pointsCount; i++) {
      points.push(
        createRandomPoint({
          x: 0,
          y: 0,
          width: canvas.width,
          height: canvas.height,
        })
      )
    }

    // draw the chaikin-ed points
    const { points: chaikinPoints } = chaikin({ points, step: 5 })
    context.lineWidth = 5
    context.strokeStyle = "black"
    context.beginPath()
    context.moveTo(chaikinPoints[0][0], chaikinPoints[0][1])
    chaikinPoints.forEach(([x, y]) => {
      context.lineTo(x, y)
    })
    context.stroke()
  </script>
</body>
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/wvKoeWR"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/wvKoeWR/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h2>{`Bonus! Further refinements`}</h2>
    <p>{`We're pretty much where we need to be already. Refreshing the page will keep creating new random glyphs. There are two more steps I want to tackle to make the glyphs more centered and more random.`}</p>
    <h3>{`Center the glyph`}</h3>
    <p>{`First, let's work on centering the glyph. To do this, my plan is to find the bounding box of the chaikin points to get the minimum and maximum `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` values in the curve. Then, we can find the center of the outer box we put the chaikin curve's random points in as well as the center of the chaikin bounding box. With both centers, we can calculate how far off the center of the smaller chaikin box is, and then shift every point in the chaikin curve that many pixels so that the centers for both boxes are the same.`}</p>
    <p>{`For that, we'll first need to create a `}<inlineCode parentName="p">{`getBounds`}</inlineCode>{` function that will return the minimum and maximum `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` values for a set of points.`}</p>
    <h4>{`Writing the getBounds function`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const getBounds = points => {
  let minX, minY, maxX, maxY

  if (!Array.isArray(points) || !points.length) {
    throw "Expected an array with more than zero elements"
  }

  points.forEach(([x, y]) => {
    // set new minimum if value:
    //  1. doesn't exist (but isn't 0)
    //  2. is less than the current minimum
    if ((!minX && minX !== 0) || x <= minX) {
      minX = x
    }

    // set new maximum if value:
    //  1. doesn't exist (but isn't 0)
    //  2. is more than the current maximum
    if ((!maxX && maxX !== 0) || x >= maxX) {
      maxX = x
    }

    if ((!minY && minY !== 0) || y <= minY) {
      minY = y
    }
    if ((!maxY && maxY !== 0) || y >= maxY) {
      maxY = y
    }
  })

  return {
    min: [minX, minY],
    max: [maxX, maxY],
  }
}
`}</code></pre>
    <h4>{`Use getBounds`}</h4>
    <p>{`Okay, now let's figure out the center of both boxes and shift the chaikin box to line up with the center of the outer box.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script>
  const getBounds = points => {
    /* ... */
  }

  const { points: chaikinPoints } = chaikin({ points, step: 5 })

  // use the getBounds function to determine the height, width, and center
  // of the chaikin curve
  const { min: chaikinMin, max: chaikinMax } = getBounds(chaikinPoints)
  const [chaikinMinX, chaikinMinY] = chaikinMin
  const [chaikinMaxX, chaikinMaxY] = chaikinMax
  const chaikinWidth = chaikinMaxX - chaikinMinX
  const chaikinHeight = chaikinMaxY - chaikinMinY
  const chaikinCenterX = chaikinMinX + chaikinWidth / 2
  const chaikinCenterY = chaikinMinY + chaikinHeight / 2

  // these values need to correspond to the x, y, width and height
  // values we used to create the random points.
  const outerBoxCenterX = 0 + canvas.width / 2
  const outerBoxCenterY = 0 + canvas.height / 2

  // find the difference between the outerBoxCenter and chaikinCenter
  const xShiftForChaikin = outerBoxCenterX - chaikinCenterX
  const yShiftForChaikin = outerBoxCenterY - chaikinCenterY

  // shift the chaikin curve points by the required shift to center them
  const shiftedChaikinPoints = chaikinPoints.map(([x, y]) => {
    return [x + xShiftForChaikin, y + yShiftForChaikin]
  })

  // draw the new, shifted chaikin curve
  context.beginPath()
  context.moveTo(shiftedChaikinPoints[0][0], shiftedChaikinPoints[0][1])
  shiftedChaikinPoints.forEach(([x, y]) => {
    context.lineTo(x, y)
  })
  context.stroke()
</script>
`}</code></pre>
    <p>{`Whew! Okay, now when you refresh the page, you should be getting a chaikin curve glyph that looks centered in the canvas.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/WNQoOpB"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/WNQoOpB/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <h3>{`Randomize the glyph creation`}</h3>
    <p>{`Last thing we'll do today is randomize the number of points in the curve, as well as whether the curve is closed. These are much easier than the centering. All we'll need to do is change the `}<inlineCode parentName="p">{`pointsCount`}</inlineCode>{` variable from a hard-coded `}<inlineCode parentName="p">{`10`}</inlineCode>{` to use two new `}<inlineCode parentName="p">{`pointsMinimum`}</inlineCode>{` and `}<inlineCode parentName="p">{`pointsVariance`}</inlineCode>{` variables.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const pointsMinimum = 5
const pointsVariance = 5
const pointsCount = pointsMinimum + Math.floor(Math.random() * pointsVariance)
`}</code></pre>
    <p>{`You can play around with the minimum and variance to get the shapes that you want.`}</p>
    <p>{`And then for randomly choosing whether the curve is closed, update the `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` call to have an `}<inlineCode parentName="p">{`isClosed`}</inlineCode>{` parameter of `}<inlineCode parentName="p">{`Math.random() > 0.5`}</inlineCode>{`. You'll also want to now return the `}<inlineCode parentName="p">{`isClosed`}</inlineCode>{` parameter where we call `}<inlineCode parentName="p">{`chaikin`}</inlineCode>{` so that we can draw the curve with the final line segment connected.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { points: chaikinPoints, isClosed: isChaikinClosed } = chaikin({
  points,
  step: 5,
  isClosed: Math.random() > 0.5,
})
`}</code></pre>
    <p>{`And then we'll update the drawing portion of the code to check whether the curve is closed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* ... */
context.beginPath()
context.moveTo(chaikinPoints[0][0], chaikinPoints[0][1])
chaikinPoints.forEach(([x, y]) => {
  context.lineTo(x, y)
})

// NEW!
if (isChaikinClosed) {
  context.lineTo(chaikinPoints[0][0], chaikinPoints[0][1])
}
context.stroke()
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`Check out the Codepen below for the code we wrote.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/matthewknudsen/pen/oNjYwmd"
      }}><div parentName="a"><iframe parentName="div" {...{
            "height": 400,
            "scrolling": "no",
            "src": "//codepen.io/matthewknudsen/embed/preview/oNjYwmd/?height=400&theme-id=undefined&default-tab=html,result",
            "frameBorder": "no",
            "allowTransparency": "true",
            "allowFullScreen": "true",
            "style": {
              "width": "100%"
            }
          }}></iframe></div></a></p>
    <p>{`From here, you'd be ready to explore what else chaikin curves could do. Some options:`}</p>
    <ol>
      <li parentName="ol">{`arrange a number of glyphs in a nice grid`}</li>
      <li parentName="ol">{`randomly place 100 chaikin curves on the screen`}</li>
      <li parentName="ol">{`lower the opacity of the curve and draw hundreds on the screen`}</li>
      <li parentName="ol">{`create a curve from a starting curve of 100 random points`}</li>
    </ol>
    <p>{`If you make something cool, send me a screenshot!`}</p>
    <p>{`Until next time ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      